import { updateAdviceScreenData } from "~/actions/screens/actions"
import Field from "~/components/forms/Field"
import FieldTextArea from "~/components/forms/FieldTextArea"
import Label from "~/components/forms/Label"
import HelpText from "~/components/HelpText"
import Delete from "~/components/icons/Delete"
import { InfoIcon } from "~/components/icons/Info"
import MarkdownTextarea from "~/components/MarkdownTextarea"
import { SingleSelect } from "~/components/selection"
import SelectionUI from "~/components/selection/SelectionUI"
import SubHeading from "~/components/SubHeading"
import TabNav from "~/components/TabNav"
import ToggleSwitch from "~/components/ToggleSwitch"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { Advisor } from "~/store/advisors"
import { AdviceScreen, CardField } from "~/store/screens"
import styled from "styled-components"
import { DataSource } from "~/types"
import Tooltip from "~/components/Tooltip"
import AdditionalFieldsImage from "~/images/tooltips/tooltip-additional-fields.jpg"
import CTAButtonImage from "~/images/tooltips/tooltip-cta-button.svg"
import { ColumnType } from "~/components/advisors/Products/Advice/ProductMapping/Filters/types"
import CustomerInfoEditor from "./CustomerInfoEditor"
import React from "react"
import TutorialLink from "~/components/TutorialLink"

const Section = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const ExtraFieldContainer = styled.div`
  display: flex;
  width: 100%;

  .btn {
    margin-left: 1em;
  }

  + .help-text {
    margin-bottom: 2em;
  }

  ${SelectionUI.DropdownWrapper} {
    margin: 0 0 0 1em;
    flex: 1;
    min-width: 0;
  }

  ${SelectionUI.SelectedValue} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;

    &:before {
      content: "";
      width: 2.25rem;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: white;
    }
  }
`

const ExtraFieldAdditions = styled.div`
  + .help-text {
    margin-bottom: 2em;
  }
`

const AdviceScreenEditor: React.FC<{
  advisor: Advisor
  screen: AdviceScreen
  dataSource?: DataSource
}> = ({ advisor, screen, dataSource }) => {
  const { t } = useTranslation()
  const { url } = useRouteMatch()

  const hasPrice = !!dataSource?.columnMapping["price"]

  const dispatch = useDispatch()

  const cardFieldOpts = dataSource
    ? Object.keys(dataSource.typeMapping)
        .map((k) => ({
          value: k,
          label: k,
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
          return 0
        })
    : []

  const sortByOptions = dataSource
    ? Object.entries(dataSource.typeMapping)
        .filter(
          ([k, v]) =>
            v.dataType === ColumnType.NUMBER &&
            k !== dataSource.columnMapping["id"]
        )
        .map(([k, v]) => {
          return {
            value: k,
            label: k,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    : []
  sortByOptions.unshift({
    value: "",
    label: t("screenEditor.fields.sort.sortByNone"),
  })

  const updateAdvice = (data: AdviceScreen) =>
    dispatch(updateAdviceScreenData(advisor.id, data))

  const applyLabelToField = (field: CardField | undefined, value: string) =>
    field ? { ...field, label: value } : field

  const applySuffixToField = (field: CardField | undefined, value: string) =>
    field ? { ...field, suffix: value } : field

  const viewExtraField = (
    screen: AdviceScreen,
    key: string,
    number: number
  ) => (
    <div key={key} style={{ marginBottom: "2rem" }}>
      <ExtraFieldContainer>
        <Label>{number}.</Label>
        <SingleSelect
          value={screen[key]?.column}
          onSelect={(value) =>
            updateAdvice({
              ...screen,
              [key]: { column: value },
            })
          }
          emptyText={"Select a property from your catalogue"}
          options={cardFieldOpts}
          disabled={!dataSource}
        />
        {screen[key] && (
          <button
            className="btn has-icon-only"
            onClick={() =>
              updateAdvice({
                ...screen,
                [key]: undefined,
              })
            }
          >
            <Delete />
          </button>
        )}
      </ExtraFieldContainer>
      {screen[key] && (
        <ExtraFieldAdditions>
          <Field
            label={"Label (optional):"}
            value={screen[key]?.label || ""}
            maxLength={150}
            placeholder={"Height"}
            onChange={(value) =>
              updateAdvice({
                ...screen,
                [key]: applyLabelToField(screen[key], value),
              })
            }
          />
          <Field
            label={"Suffix (optional):"}
            value={screen[key]?.suffix || ""}
            maxLength={150}
            placeholder={"cm"}
            onChange={(value) =>
              updateAdvice({
                ...screen,
                [key]: applySuffixToField(screen[key], value),
              })
            }
          />
        </ExtraFieldAdditions>
      )}
    </div>
  )

  return (
    <>
      <Section>
        <TabNav
          withUnderLineBorder={true}
          exact={true}
          options={
            [
              {
                label: (
                  <>
                    <span>Content</span>
                  </>
                ),
                link: `${url}`,
              },
              {
                label: (
                  <>
                    <span>Product content</span>
                  </>
                ),
                link: `${url}/products`,
              },
              {
                label: (
                  <>
                    <span>Customer contact</span>
                  </>
                ),
                link: `${url}/customer-contact`,
              },
            ].filter((elem) => !!elem) as any
          }
        />
      </Section>

      <Switch>
        <Route
          path={`${url}/products`}
          exact
          render={({ match }) => (
            <>
              <h3 style={{ marginBottom: "0.5rem" }}>The basics</h3>
              <Field
                label={t("screenEditor.fields.bestMatchLabel")}
                value={screen.bestMatchText}
                maxLength={64}
                onChange={(value) =>
                  updateAdvice({ ...screen, bestMatchText: value })
                }
                disabled={!screen.showBestMatch}
                placeholder={"Best match"}
                labelExtra={
                  <ToggleSwitch
                    toggledOn={screen.showBestMatch}
                    label={t("screenEditor.fields.showBestMatchLabel")}
                    flipLabel
                    onChange={(show) =>
                      updateAdvice({ ...screen, showBestMatch: show })
                    }
                  />
                }
                htmlExtra={
                  <HelpText>{t("screenEditor.bestMatchHelpText")}</HelpText>
                }
              />

              <ToggleSwitch
                label={t("screenEditor.fields.showMatchingIndicatorsLabel")}
                onChange={(value) => {
                  updateAdvice({
                    ...screen,
                    showMatchingIndicators: value,
                  })
                }}
                toggledOn={screen.showMatchingIndicators}
              />
              <HelpText style={{ marginTop: 0 }}>
                {t("screenEditor.showMatchingIndicatorsHelpText")}
              </HelpText>

              <Field
                label={
                  <>
                    {t("screenEditor.fields.adviceButtonText")}
                    <Tooltip
                      content={
                        <>
                          <img
                            src={CTAButtonImage}
                            height="300"
                            alt={t("screenEditor.fields.adviceButtonText")}
                          />
                          <strong>{t("tooltips.CTAButton.header")}</strong>
                          <p>{t("tooltips.CTAButton.body")}</p>
                        </>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </>
                }
                value={screen.buttonText}
                maxLength={64}
                onChange={(value) =>
                  updateAdvice({ ...screen, buttonText: value })
                }
                disabled={!screen.showProductLink}
                labelExtra={
                  <ToggleSwitch
                    toggledOn={screen.showProductLink}
                    label={t("screenEditor.fields.showProductLinkText")}
                    flipLabel
                    onChange={(show) =>
                      updateAdvice({ ...screen, showProductLink: show })
                    }
                  />
                }
                htmlExtra={
                  <HelpText>{t("screenEditor.adviceButtonTextHelp")}</HelpText>
                }
              />

              <hr />
              <h3>Price formatting</h3>
              <ToggleSwitch
                label={t("screenEditor.fields.showPriceLabel")}
                onChange={(value) => {
                  updateAdvice({
                    ...screen,
                    showPrice: value,
                  })
                }}
                disabled={!hasPrice}
                toggledOn={hasPrice && screen.showPrice}
              />
              <HelpText style={{ marginTop: 0 }}>
                {t("screenEditor.showPriceHelpText")}
              </HelpText>

              <ToggleSwitch
                label={t("screenEditor.fields.showCurrencyLabel")}
                onChange={(value) => {
                  updateAdvice({
                    ...screen,
                    showCurrency: value,
                  })
                }}
                disabled={!hasPrice || !screen.showPrice}
                toggledOn={hasPrice && screen.showCurrency}
              />
              <HelpText style={{ marginTop: 0 }}>
                {t("screenEditor.showCurrencyHelpText")}
              </HelpText>

              <Field
                label={"Text shown before the price"}
                value={screen.pricePrefix}
                placeholder="Example: Starting at"
                maxLength={16}
                onChange={(value) =>
                  updateAdvice({ ...screen, pricePrefix: value })
                }
                onBlur={(value) =>
                  updateAdvice({ ...screen, pricePrefix: value.trim() })
                }
                disabled={!screen.showPricePrefix || !screen.showPrice}
                labelExtra={
                  <ToggleSwitch
                    toggledOn={screen.showPricePrefix}
                    disabled={!screen.showPrice}
                    label={"Show"}
                    flipLabel
                    onChange={(show) =>
                      updateAdvice({ ...screen, showPricePrefix: show })
                    }
                  />
                }
              />
              <Field
                label={"Text shown after the price"}
                value={screen.priceSuffix}
                placeholder="Example: per m2"
                maxLength={16}
                onChange={(value) =>
                  updateAdvice({ ...screen, priceSuffix: value })
                }
                onBlur={(value) =>
                  updateAdvice({ ...screen, priceSuffix: value.trim() })
                }
                disabled={!screen.showPriceSuffix || !screen.showPrice}
                labelExtra={
                  <ToggleSwitch
                    toggledOn={screen.showPriceSuffix}
                    label={"Show"}
                    disabled={!screen.showPrice}
                    flipLabel
                    onChange={(show) =>
                      updateAdvice({ ...screen, showPriceSuffix: show })
                    }
                  />
                }
              />
              <hr />

              {dataSource && (
                <>
                  <h3 style={{ marginBottom: "0.5rem" }}>
                    Product information
                  </h3>
                  <p className="subtle-text" style={{ marginTop: 0 }}>
                    Display additional information about the product.
                  </p>
                  <Label>
                    Add up to three additional fields (optional)
                    <Tooltip
                      content={
                        <>
                          <img
                            src={AdditionalFieldsImage}
                            height="350"
                            alt={"Additional fields example"}
                          />
                          <strong> Additional fields </strong>
                          <p>
                            You may add up to 3 additional fields that are shown
                            on each recommended product. For example, you could
                            select the property 'description' to show the
                            product description on each product card. For a
                            property like 'height', it may make sense to add a
                            label ("Height") and/or suffix ("cm"). Click
                            ‘Preview’ to see for yourself!
                          </p>
                        </>
                      }
                      position="top center"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Label>
                  {["extraField1", "extraField2", "extraField3"].map((v, i) =>
                    viewExtraField(screen, v, i + 1)
                  )}
                </>
              )}
            </>
          )}
        />
        <Route
          path={`${url}/customer-contact`}
          exact
          render={({ match }) => <CustomerInfoEditor advisor={advisor} />}
        />
        <Route
          path={`${url}`}
          exact
          render={({ match }) => (
            <>
              <FieldTextArea
                label={t("screenEditor.fields.title")}
                value={screen.title}
                maxLength={255}
                onChange={(value) => updateAdvice({ ...screen, title: value })}
              />
              <MarkdownTextarea
                label={t("screenEditor.fields.text")}
                isOptional={true}
                text={screen.text}
                maxLength={700}
                onChange={(value) => updateAdvice({ ...screen, text: value })}
                bounds={"#sidebar"}
                dataTestId={"advice-text"}
              />
              <MarkdownTextarea
                label={t("screenEditor.fields.additionalInformation")}
                isOptional={true}
                text={screen.info}
                maxLength={700}
                onChange={(value) => updateAdvice({ ...screen, info: value })}
                bounds={"#sidebar"}
                dataTestId={"advice-additional"}
                toolbar={["bold", "link"]}
                italic
              />

              <SubHeading>Empty advice</SubHeading>

              <p className="subtle-text">
                {t("screenEditor.fields.noResultsHelpText")}
              </p>
              <TutorialLink
                article="empty-advice"
                label="Empty advices in my product finder"
              />
              <FieldTextArea
                label={t("screenEditor.fields.noResultsTitle")}
                isOptional={true}
                value={screen.noResultsTitle}
                maxLength={255}
                onChange={(value) =>
                  updateAdvice({ ...screen, noResultsTitle: value })
                }
              />
              <MarkdownTextarea
                label={t("screenEditor.fields.noResultsText")}
                isOptional={true}
                text={screen.noResultsText}
                maxLength={700}
                onChange={(value) =>
                  updateAdvice({ ...screen, noResultsText: value })
                }
                bounds={"#sidebar"}
                dataTestId={"advice-noresults"}
              />
            </>
          )}
        />
        <Redirect to={`${url}`} />
      </Switch>
    </>
  )
}
export default AdviceScreenEditor
