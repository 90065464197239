import Quill from "quill"
import TurndownService from "turndown"
import { marked } from "marked"
import DOMPurify from "dompurify"

const turndownService = new TurndownService()

class QuillEditor extends HTMLElement {
  static get observedAttributes() {
    // Which attributes call attributeChangedCallback
    return ["disabled"]
  }
  constructor() {
    super()
  }

  editor: undefined | any

  connectedCallback() {
    if (this.isConnected) {
      const value: string = this.getAttribute("content") || ""
      const bounds: string = this.getAttribute("bounds") || "#sidebar"
      const disabled: boolean = this.getAttribute("disabled") == "true"
      const toolbarOpts: string[] = (this.getAttribute("toolbar") || "").split(
        ","
      )
      const formats: string[] = (this.getAttribute("formats") || "").split(
        ","
      )
      const toolbar = toolbarOpts.includes("list")
        ? [
            toolbarOpts.filter((a) => a !== "list"),
            [{ list: "ordered" }, { list: "bullet" }],
          ]
        : toolbarOpts
      const maxLength: number = parseInt(this.getAttribute("maxlength") || "")
      const hasMaxLength = isFinite(maxLength) && !isNaN(maxLength)

      const wrapper = document.createElement("div")
      wrapper.innerHTML = DOMPurify.sanitize(marked.parse(value))
      this.appendChild(wrapper)

      var quill = new Quill(wrapper, {
        modules: {
          toolbar: toolbar,
        },
        formats: formats,
        theme: "snow",
        bounds: bounds,
      })

      var Link = Quill.import("formats/link")
      Link.sanitize = function (url) {
        if (!url.match(/^([a-zA-Z]+:)?\/\//)) return "https://" + url
        return url
      }

      quill.on("text-change", (_delta, _oldDelta, _source) => {
        if (hasMaxLength && quill.getLength() > maxLength) {
          quill.deleteText(maxLength, quill.getLength())
        }
        const value = turndownService.turndown(quill.root.innerHTML)
        const event = new CustomEvent("input", { detail: value })

        this.dispatchEvent(event)
      })

      let hasFocus = false
      quill.on("selection-change", (range, oldRange, source) => {
        const focusEvent = new CustomEvent("focus", {})
        if (range && this.getAttribute("disabled") != "true") {
          if (range.length == 0) {
            // User cursor is on
            if (!hasFocus) {
              this.dispatchEvent(focusEvent)
              hasFocus = true
            }
          } else {
            // User has highlighted
            // var text = quill.getText(range.index, range.length);
            if (!hasFocus) {
              this.dispatchEvent(focusEvent)
              hasFocus = true
            }
          }
        } else {
          // Cursor not in the editor
          // this.dispatchEvent(blurEvent)
          hasFocus = false
        }
      })
      quill.enable(!disabled)
      this.editor = quill
    }
  }

  // Update content if changed
  attributeChangedCallback(_name, _oldValue, newValue) {
    if (_name === "disabled") {
      this.editor?.enable(newValue === "false")
    }
  }
}

const define = () => customElements.define("quill-editor", QuillEditor)

export { define }
