import { FeatureFlag } from "~/hooks/useFeatureFlags"
import { ActionType } from "typesafe-actions"
import { SET_FEATURE_FLAG, setFlag } from "~/actions/featureFlags"
import { fetchUserAccountSuccess } from "~/actions/currentUser/actions"
import { FETCH_USER_ACCOUNT_SUCCESS } from "~/actions/currentUser/constants"

export type FeatureFlagState = Record<FeatureFlag, boolean>

const actions = { setFlag, fetchUserAccountSuccess }
type ActionTypes = ActionType<typeof actions>

const defaultState: FeatureFlagState = {
  [FeatureFlag.customerInfoCollectionEnabled]: false,
  [FeatureFlag.showPoweredByAiden]: false,
  [FeatureFlag.newCreateAppFlowEnabled]: false,
  [FeatureFlag.packageAdviceEnabled]: false,
  [FeatureFlag.pdpIntegrationEnabled]: false,
  [FeatureFlag.translationsEnabled] : false,

  // Analytics Integrations
  [FeatureFlag.bloomreachEnabled]: false,
  [FeatureFlag.googleAnalyticsEnabled]: false,
  [FeatureFlag.spotlerActivateEnabled]: false,
  [FeatureFlag.customIntegrationEnabled]: false,
}

const featureFlagReducer = (
  state: FeatureFlagState = defaultState,
  action: ActionTypes
): FeatureFlagState => {
  switch (action.type) {
    case SET_FEATURE_FLAG: {
      const { flag, enabled } = action.payload
      return {
        ...state,
        [flag]: enabled,
      }
    }
    case FETCH_USER_ACCOUNT_SUCCESS: {
      const { account } = action.payload

      return {
        ...state,
        [FeatureFlag.customerInfoCollectionEnabled]:
          account.featureFlags.customerInfoCollectionEnabled,
        [FeatureFlag.showPoweredByAiden]:
          account.featureFlags.showPoweredByAiden,
        [FeatureFlag.newCreateAppFlowEnabled]:
          account.featureFlags.newCreateAppFlowEnabled,
        [FeatureFlag.packageAdviceEnabled]:
          account.featureFlags.packageAdviceEnabled,
        [FeatureFlag.pdpIntegrationEnabled]:
          account.featureFlags.pdpIntegrationEnabled,
        [FeatureFlag.translationsEnabled]:
          account.featureFlags.translationsEnabled,

        // Integrations
        [FeatureFlag.bloomreachEnabled]: account.featureFlags.bloomreachEnabled,
        [FeatureFlag.googleAnalyticsEnabled]:
          account.featureFlags.googleAnalyticsEnabled,
        [FeatureFlag.spotlerActivateEnabled]:
          account.featureFlags.spotlerActivateEnabled,
        [FeatureFlag.customIntegrationEnabled]:
          account.featureFlags.customIntegrationEnabled,
      }
    }
    default:
      return state
  }
}

export default featureFlagReducer
