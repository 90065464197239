import { UUID } from "~/store/types"
import { v4 as uuid } from "uuid"
import {
  DrilldownAnswerOption,
  NumericalInputType,
  Question,
  QuestionMediaType,
  QuestionType,
} from "~/store/questions"
import {
  AdvisorExternal,
  ConversationScreenExternal,
  ExternalAdviceScreen,
  ExternalInfoScreen,
  ExternalIntroScreen,
  QuestionExternal,
} from "~/api/advisors"
import { Advisor } from "~/store/advisors"
import { Answer } from "~/store/answers/types"
import { Rule } from "~/store/rules"
import {
  ConversationScreens,
  ScreenMediaType,
  ScreenType,
  ShowBackorder,
  SortOrder,
} from "~/store/screens"
import { defaultAdvisorLocale } from "~/i18n"
import { sortBy } from "lodash"
import { Flow } from "~/store/flow"

const DEFAULT_ICON = ":tv:"
const DEFAULT_PRODUCT_LABEL = "products"

export const defaultAdvisor = (
  name?: string,
  productLabel?: string,
  icon?: string,
  advisorId?: UUID,
  settings?: {
    locales?: string[]
  }
): Advisor & { screens: ConversationScreens } => {
  const id = advisorId || uuid()
  const questions = []

  return {
    id,
    name: name || `New Advisor`,
    productLabel: productLabel || "",
    icon: icon || ":tv:",
    locales: settings?.locales || [defaultAdvisorLocale],
    analytics: {},
    questions,
    screenOrder: [],
    style: {
      primary: "#00dfa7",
      focus: "#00dfa7",
      text: "#565656",
      background: "#F7F9FC",
      selectHover: "#fafafa",
      font: "Nunito Sans",
      fontHeader: "Montserrat",
      header: ["bold"],
      headerColor: "#1D264D",
      buttonPaddingVertical: "0.75rem",
      buttonPaddingHorizontal: "1.25rem",
      borderRadius: "0.25rem",
      borderRadiusCard: "0.25rem",
      boxShadowOn: true,
    },
    screens: defaultScreens(settings?.locales || [defaultAdvisorLocale]),
    customerInfo: defaultAdviceCustomerInfoConfig(),
    embedConfig: defaultEmbedConfig(),
    appUpdateInfo: { updatedAt: null },
  }
}

const defaultEmbedConfig = () => {
  return {
    modalButtonText: undefined,
    inPageOffset: 100,
    inPageShowStartScreenInfo: true,
    fullPageOffset: 100,
  }
}

export const defaultAdviceCustomerInfoConfig = () => {
  return {
    showForm: false,
    title: "",
    intro: "",
    ctaButtonLabel: "",
    showCheckbox: false,
    checkboxLabel: "",
    formSubmittedMessage: "",
  }
}

export const defaultNoResultsCustomerInfoConfig = () => {
  return {
    showForm: false,
    showCheckbox: false,
    checkboxLabel: "",
    formSubmittedMessage: "",
  }
}

export const defaultInfoPageCustomerInfoConfig = () => {
  return {
    showForm: false,
    showCheckbox: false,
    checkboxLabel: "",
    formSubmittedMessage: "",
    formIdentifier: "",
  }
}

const defaultScreens = (locales: string[]): ConversationScreens => ({
  intro: {
    id: uuid(),
    type: ScreenType.INTRO,
    title: defaultStartTitle(locales),
    text: "",
    info: "",
    buttonText: defaultStartButton(locales),
    skipIntro: false,
    next: "ADVICE",
  },
  advice: {
    id: uuid(),
    type: ScreenType.ADVICE,
    title: defaultAdviceTitle(locales),
    text: "",
    info: "",
    buttonText: "",
    bestMatchText: "",
    noResultsTitle: "",
    noResultsText: "",
    noResultsCustomerInfo: defaultNoResultsCustomerInfoConfig(),
    resultSize: 3,
    showProductLink: true,
    showBestMatch: true,
    showMatchingIndicators: true,
    showPrice: true,
    showCurrency: true,
    showPriceSuffix: false,
    showPricePrefix: false,
    priceSuffix: "",
    pricePrefix: "",
    sort: {
      sortBy: "",
      sortOrder: SortOrder.DESC,
    },
    groupByVariants: false,
    displayVariantsOfGroup: {
      enabled: false,
      field: null,
    },
    productScopeConfig: {
      enabled: false,
      bestFitTitle: "",
      bestFitAltTitle: "",
      goodFitTitle: "",
      goodFitAltTitle: "",
      badFitTitle: "",
      badFitAltTitle: "",
      emptyAdviceTitle: "",
      emptyAdviceText: "",
    },
    showBackorder: ShowBackorder.ALWAYS,
  },
  infoScreens: [],
})

type DefaultTranslations = {
  "en-US": string
  "en-GB": string
  nl: string
  da: string
  de: string
  fi: string
  fr: string
  it: string
  no: string
  es: string
  sv: string
  pl: string
  pt: string
}

const getDefault = (locales: string[], defaults: DefaultTranslations) =>
  defaults[locales[0]] || defaults["en-US"]

const defaultStartTitle = (locales: string[]) =>
  getDefault(locales, {
    "en-US": "Not sure what suits your needs?",
    "en-GB": "Not sure what suits your needs?",
    nl: "Twijfel je wat bij je past?",
    da: "Ikke sikker på, hvad der passer dig?",
    de: "Sie sind sich nicht sicher, was zu Ihnen passt?",
    fr: "Vous ne savez pas ce qui vous convient?",
    it: "Non sei sicuro di cosa ti si addice?",
    es: "¿No estás seguro de lo que te conviene?",
    fi: "Etkö ole varma, mikä sopii tarpeisiisi?",
    no: "Ikke sikker på hva som passer dine behov?",
    sv: "Är du osäker på vad som passar dina behov?",
    pl: "Nie jesteś pewien, co Ci odpowiada?",
    pt: "Você não tem certeza do que combina com você?",
  })

const defaultStartButton = (locales: string[]) =>
  getDefault(locales, {
    "en-US": "Start",
    "en-GB": "Start",
    nl: "Beginnen",
    da: "Begynde",
    de: "Anfangen",
    fr: "Commencer",
    it: "Inizare",
    es: "Iniciar",
    fi: "Alkaa",
    no: "Begynne",
    sv: "Börja",
    pl: "Zacząć",
    pt: "Para iniciar",
  })

const defaultAdviceTitle = (locales: string[]) =>
  getDefault(locales, {
    "en-US": "This should suit your needs.",
    "en-GB": "This should suit your needs.",
    nl: "Dit past bij jou.",
    da: "Dette passer til dig.",
    de: "Das passt zu Ihnen.",
    fr: "Cela vous convient.",
    it: "Questo ti si addice.",
    es: "Esto te conviene.",
    fi: "Tämän pitäisi sopia tarpeisiisi.",
    no: "Dette bør passe dine behov.",
    sv: "Detta bör passa dina behov.",
    pl: "To powinno odpowiadać Twoim potrzebom.",
    pt: "Isso deve atender às suas necessidades.",
  })

export const internalToExternal = (
  advisor: Advisor,
  questions: Question[],
  answers: Answer[],
  rules: Rule[],
  screens: ConversationScreens,
  flow: Flow
): AdvisorExternal => {
  const answerIds = answers.map((a) => a.id)

  const rulesWithoutMissingAnswers = rules.map((r) => ({
    ...r,
    targetQuestionId: flow.rules.find((a) => a.ruleId === r.id)?.target,
  }))
  // .map((r) => {
  //   if (r.left?.kind === ExpressionType.Multi) {
  //     return {
  //       ...r,
  //       left: {
  //         ...r.left,
  //         right: r.left.right?.filter((answerId) =>
  //           answerIds.includes(answerId)
  //         ),
  //       },
  //     }
  //   } else if (r.left?.kind === ExpressionType.Single) {
  //     return {
  //       ...r,
  //       left: {
  //         ...r.left,
  //         right:
  //           r.left.right && answerIds.includes(r.left.right)
  //             ? r.left.right
  //             : undefined,
  //       },
  //     }
  //   } else {
  //     return r
  //   }
  // })

  const sortedQuestions: QuestionExternal[] = questions.map((q) => {
    return {
      ...q,
      advisorId: advisor.id,
      answers:
        q.type === QuestionType.Drilldown
          ? []
          : answers
              .filter((a) => q.answers.includes(a.id))
              .map((answer) => ({ questionId: q.id, ...answer })),
      rules: rulesWithoutMissingAnswers.filter((r) => q.rules.includes(r.id)),
      next: flow.nodes[q.id],
    }
  })

  return {
    id: advisor.id,
    name: advisor.name,
    productLabel: advisor.productLabel,
    icon: advisor.icon,
    analytics: advisor.analytics,
    questions: sortedQuestions,
    style: advisor.style,
    locales: advisor.locales,
    screens: advisorScreensToExternal(advisor.id, screens, flow),
    customerInfo: advisor.customerInfo,
    embedConfig: advisor.embedConfig,
    appUpdate: {
      updatedAt: advisor.appUpdateInfo.updatedAt?.valueOf() || null,
    },
    screenOrder: advisor.screenOrder.reduce(
      (acc, screenId, i) => ({
        ...acc,
        [screenId]: i,
      }),
      {}
    ),
  }
}

const advisorScreensToExternal = (
  advisorId: string,
  screens: ConversationScreens,
  flow: Flow
): ConversationScreenExternal[] => {
  return [
    {
      advisorId: advisorId,
      id: screens.intro.id,
      type: screens.intro.type,
      next: flow.nodes["START"],
      config: {
        title: screens.intro.title,
        text: screens.intro.text,
        info: screens.intro.info,
        buttonText: screens.intro.buttonText,
        skipIntro: screens.intro.skipIntro,
      },
    } as ExternalIntroScreen,
    {
      advisorId: advisorId,
      id: screens.advice.id,
      type: screens.advice.type,
      noResultsCustomerInfoConfig: screens.advice.noResultsCustomerInfo,
      productScopeConfig: screens.advice.productScopeConfig,
      config: {
        title: screens.advice.title,
        text: screens.advice.text,
        info: screens.advice.info,
        buttonText: screens.advice.buttonText,
        bestMatchText: screens.advice.bestMatchText,
        noResultsTitle: screens.advice.noResultsTitle,
        noResultsText: screens.advice.noResultsText,
        resultSize: screens.advice.resultSize.toString(),
        showProductLink: screens.advice.showProductLink,
        sort: screens.advice.sort.sortBy === "" ? [] : [screens.advice.sort],
        extraField1: screens.advice.extraField1,
        extraField2: screens.advice.extraField2,
        extraField3: screens.advice.extraField3,
        showBestMatch: screens.advice.showBestMatch,
        showMatchingIndicators: screens.advice.showMatchingIndicators,
        showPrice: screens.advice.showPrice,
        showCurrency: screens.advice.showCurrency,
        groupByVariants: screens.advice.groupByVariants,
        displayVariantsOfGroup: screens.advice.displayVariantsOfGroup,

        showPriceSuffix: screens.advice.showPriceSuffix,
        showPricePrefix: screens.advice.showPricePrefix,
        priceSuffix: screens.advice.priceSuffix,
        pricePrefix: screens.advice.pricePrefix,

        showBackorder: screens.advice.showBackorder,
      },
    } as ExternalAdviceScreen,
    ...screens.infoScreens.map((scr) => {
      return {
        advisorId: advisorId,
        id: scr.id,
        type: scr.type,
        next: flow.nodes[scr.id] === undefined ? null : flow.nodes[scr.id],
        customerInfoConfig: scr.customerInfo,
        config: {
          title: scr.title,
          text: scr.text,
          info: scr.info,
          label: scr.label,
          color: scr.color,
          mediaType:
            scr.mediaType === ScreenMediaType.SCREEN_WITH_VIDEO
              ? QuestionMediaType.QUESTION_WITH_VIDEO
              : scr.mediaType === ScreenMediaType.SCREEN_WITH_IMAGE
              ? QuestionMediaType.QUESTION_WITH_IMAGE
              : QuestionMediaType.NO_MEDIA,
        },
      } as ExternalInfoScreen
    }),
  ]
}

const advisorScreensExternalToInternal = (
  screens: ConversationScreenExternal[] = [],
  locales: string[]
): ConversationScreens => {
  const introScreen = screens.find((s) => s.type === ScreenType.INTRO) as
    | ExternalIntroScreen
    | undefined
  const adviceScreen = screens.find((s) => s.type === ScreenType.ADVICE) as
    | ExternalAdviceScreen
    | undefined
  const infoScreens = screens.filter(
    (s) => s.type === ScreenType.INFO
  ) as ExternalInfoScreen[]

  return {
    intro: introScreen
      ? {
          id: introScreen.id,
          type: ScreenType.INTRO,
          title: introScreen.config.title,
          text: introScreen.config.text || "",
          info: introScreen.config.info || "",
          buttonText: introScreen.config.buttonText || "",
          skipIntro: parseStringBool(introScreen.config.skipIntro || false),
          next: introScreen.next,
        }
      : defaultScreens(locales).intro,
    advice: adviceScreen
      ? {
          id: adviceScreen.id,
          type: ScreenType.ADVICE,
          title: adviceScreen.config.title,
          text: adviceScreen.config.text || "",
          info: adviceScreen.config.info || "",
          buttonText: adviceScreen.config.buttonText || "",
          bestMatchText: adviceScreen.config.bestMatchText || "",
          noResultsTitle: adviceScreen.config.noResultsTitle || "",
          noResultsText: adviceScreen.config.noResultsText || "",
          noResultsCustomerInfo: adviceScreen.noResultsCustomerInfoConfig,
          resultSize: parseInt(adviceScreen.config.resultSize || "3"),
          showBestMatch: adviceScreen.config.showBestMatch,
          showMatchingIndicators: adviceScreen.config.showMatchingIndicators,
          showPrice: adviceScreen.config.showPrice,
          showCurrency: adviceScreen.config.showCurrency,
          showProductLink:
            typeof adviceScreen.config.showProductLink === "string"
              ? parseStringBool(adviceScreen.config.showProductLink)
              : typeof adviceScreen.config.showProductLink === "boolean"
              ? adviceScreen.config.showProductLink
              : true,
          sort: {
            sortBy: adviceScreen.config.sort?.[0]?.sortBy || "",
            sortOrder:
              adviceScreen.config.sort?.[0]?.sortOrder || SortOrder.ASC,
          },
          extraField1: adviceScreen.config.extraField1,
          extraField2: adviceScreen.config.extraField2,
          extraField3: adviceScreen.config.extraField3,
          groupByVariants: adviceScreen.config.groupByVariants,
          displayVariantsOfGroup: adviceScreen.config.displayVariantsOfGroup,
          productScopeConfig: adviceScreen.productScopeConfig,
          showPriceSuffix: adviceScreen.config.showPriceSuffix || false,
          showPricePrefix: adviceScreen.config.showPricePrefix || false,
          priceSuffix: adviceScreen.config.priceSuffix || "",
          pricePrefix: adviceScreen.config.pricePrefix || "",
          showBackorder: adviceScreen.config.showBackorder,
        }
      : defaultScreens(locales).advice,
    infoScreens: infoScreens.map((screen) => ({
      id: screen.id,
      type: ScreenType.INFO,
      label: screen.config.label,
      title: screen.config.title,
      text: screen.config.text || "",
      info: screen.config.info || "",
      color: screen.config.color,
      mediaType:
        screen.config.mediaType === QuestionMediaType.QUESTION_WITH_VIDEO
          ? ScreenMediaType.SCREEN_WITH_VIDEO
          : screen.config.mediaType === QuestionMediaType.QUESTION_WITH_IMAGE
          ? ScreenMediaType.SCREEN_WITH_IMAGE
          : ScreenMediaType.SCREEN_WITH_NO_MEDIA,
      next: screen.next || undefined,
      customerInfo: screen.customerInfoConfig,
    })),
  }
}

const parseStringBool = (val: string | boolean): boolean =>
  typeof val === "boolean" ? val : val === "true" ? true : false

export const listExternalToInternal = (
  advisors: AdvisorExternal[]
): Advisor[] => advisors.map(externalToInternal)

export const externalToInternal = (
  advisor: AdvisorExternal
): Advisor & { screens: ConversationScreens } => ({
  id: advisor.id,
  name: advisor.name,
  productLabel: advisor.productLabel || DEFAULT_PRODUCT_LABEL,
  icon: advisor.icon || DEFAULT_ICON,
  analytics: advisor.analytics,
  questions: advisor.questions.map((q) => q.id),
  screenOrder: sortBy(Object.entries(advisor.screenOrder), ([s, i]) => i).map(
    ([s, i]) => s
  ),
  updatedAt: advisor.updatedAt ? new Date(advisor.updatedAt) : undefined,
  status: advisor.status,
  locales: advisor.locales || [defaultAdvisorLocale],
  style: {
    primary: advisor.style.primary || "#ffcb1b",
    focus: advisor.style.focus || "#00dfa7",
    text: advisor.style.text || "#565656",
    background: advisor.style.background || "#FBFBFC",
    selectHover: advisor.style.selectHover || "#F7F9FC",
    font: advisor.style.font || "Nunito Sans",
    fontHeader: advisor.style.fontHeader || advisor.style.font || "Montserrat",
    header: advisor.style.header || ["bold"],
    headerColor: advisor.style.headerColor || "#1D264D",
    buttonPaddingVertical: advisor.style.buttonPaddingVertical || "0.75rem",
    buttonPaddingHorizontal: advisor.style.buttonPaddingHorizontal || "1.25rem",
    borderRadius: advisor.style.borderRadius || "0.25rem",
    borderRadiusCard: advisor.style.borderRadiusCard || "0.25rem",
    boxShadowOn: !!advisor.style.boxShadowOn,
  },
  screens: advisorScreensExternalToInternal(
    advisor.screens,
    advisor.locales || [defaultAdvisorLocale]
  ),
  customerInfo: advisor.customerInfo,
  embedConfig: advisor.embedConfig,
  appUpdateInfo: {
    updatedAt: advisor.appUpdate.updatedAt
      ? new Date(advisor.appUpdate.updatedAt)
      : null,
  },
})

export const externalToInternalExpanded = (advisor: AdvisorExternal) => {
  const questions: Question[] = advisor.questions.map((q) => {
    switch (q.type) {
      case QuestionType.Numeric:
        return {
          id: q.id,
          title: q.title,
          helpText: q.helpText || "",
          label: q.label || "label",
          color: q.color || "Color1",
          next: q.next || undefined,
          answers: q.answers.map((a) => a.id),
          rules: q.rules.map((a) => a.id),
          type: QuestionType.Numeric,
          mediaType: q.mediaType,
          config: {
            inputType: NumericalInputType.INPUT,
            filter: q.config.filter,
            info: q.config.info,
            placeholder: q.config.placeholder,
            optional: q.config.optional,
            allowDecimals: q.config.allowDecimals,
            inputInfo: q.config.inputInfo,
            matchingFeatureLabel: q.config.matchingFeatureLabel,
          },
        }
      case QuestionType.Drilldown:
        return {
          ...q,
          type: QuestionType.Drilldown,
          helpText: q.helpText || "",
          label: q.label || "label",
          color: q.color || "Color1",
          next: q.next || undefined,
          answers: drilldownAnswerOptionsToAnswers(q.config.answers).map(
            (a) => a.id
          ),
          rules: q.rules.map((a) => a.id),
          mediaType: q.mediaType,
          config: {
            filter: q.config.filter,
            matchPath: q.config.matchPath,
            answers: q.config.answers,
            levels: q.config.levels,
            levelConfiguration: q.config.levelConfiguration,
          },
        }
      case QuestionType.AutoDrilldown:
        return {
          ...q,
          type: QuestionType.AutoDrilldown,
          helpText: q.helpText || "",
          label: q.label || "label",
          color: q.color || "Color1",
          next: q.next || undefined,
          answers: q.answers.map((a) => a.id),
          rules: q.rules.map((a) => a.id),
          mediaType: q.mediaType,
          config: {
            filter: q.config.filter,
            matchPath: q.config.matchPath,
            levels: q.config.levels,
            levelConfiguration: q.config.levelConfiguration,
          },
        }
      default:
        return {
          ...q,
          type: q.type,
          helpText: q.helpText || "",
          label: q.label || "label",
          color: q.color || "Color1",
          next: q.next || undefined,
          answers: q.answers.map((a) => a.id),
          rules: q.rules.map((a) => a.id),
          mediaType: q.mediaType,
          config: {
            filter: q.config.filter,
          },
        }
    }
  })

  const rules = advisor.questions.flatMap((q) =>
    q.rules.map((r) => ({
      id: r.id,
      predicate: r.predicate,
      targetQuestionId: r.targetQuestionId || undefined,
    }))
  )

  const answers = advisor.questions.flatMap((q) => q.answers)

  return {
    advisor: externalToInternal(advisor),
    questions,
    rules,
    answers: answers,
    screens: advisorScreensExternalToInternal(
      advisor.screens,
      advisor.locales || [defaultAdvisorLocale]
    ),
  }
}

function answersForDrilldown(question: Question): Answer[] {
  if (question.type === QuestionType.Drilldown) {
    return drilldownAnswerOptionsToAnswers(question.config.answers)
  } else {
    return []
  }
}

function drilldownAnswerOptionsToAnswers(
  answerOptions: DrilldownAnswerOption[],
  prefix = ""
): Answer[] {
  const answers = answerOptions.reduce((acc, option) => {
    if ("id" in option) {
      return [
        {
          id: option.id,
          title: prefix + option.title,
          helpText: "",
          matchingFeatureLabel: "",
          isNeutralAnswer: false,
        },
        ...acc,
      ]
    } else {
      return [
        ...drilldownAnswerOptionsToAnswers(
          option.answers,
          prefix + option.title + " - "
        ),
        ...acc,
      ]
    }
  }, [] as Answer[])

  return sortBy(answers, (a) => a.title.toLocaleLowerCase())
}

export default {
  defaultAdvisor,
  internalToExternal,
  listExternalToInternal,
  answersForDrilldown,
  externalToInternal,
  externalToInternalExpanded,
}
