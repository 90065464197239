const start = 1721905702 // 25th july
const end = 1721939400 // 25th july 22:30
import styled from "styled-components"

const Banner = styled.div`
  background: #150c3c;
  color: #f1fbff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.5rem;
  text-wrap: pretty;
  background-size: cover;
  background-position: center 25%;
  p {
    font-weight: 600;
    margin: 0 0 0.25rem 0;
  }
`

const MaintenanceNotice: React.FC<{}> = () => {
  const now = new Date().getTime() / 1000
  const show = now >= start && now <= end
  return show ? (
    <Banner>
      <p>
          On July 25th from 21:00 until 22:30 CEST,  the CMS will be unavailable due to scheduled maintenance.
          Your apps are not affected and will function as normal.
      </p>
    </Banner>
  ) : null
}

export default MaintenanceNotice
