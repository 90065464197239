import Elm from "./ElmWrapper"
import { useDispatch } from "react-redux"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import { changeAdvisorMeta } from "~/actions/advisors"
import { useHistory } from "react-router"
import useNotifications from "~/hooks/useNotifications"
import { useState } from "react"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"

const AdvisorSettings: React.FC<{
  advisorId?: string
  apiUrl: string
  authtoken: string
  mode: string
}> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { notify } = useNotifications()
  const advisorId = props.advisorId
  const [ports, setPorts] = useState<any>(undefined)
  const { featureFlags } = useFeatureFlags()

  const changeAdvisorSettings = (settings) =>
    advisorId &&
    dispatch(
      changeAdvisorMeta(
        advisorId,
        settings.advisorName,
        settings.productLabel,
        settings.emojiIcon,
        Object.fromEntries(settings.analyticsParameters),
        [settings.language],
        false
      )
    )

  function setupPorts(ports) {
    setPorts(ports)
    ports.advisorSettingsChanged.subscribe(function (settings) {
      changeAdvisorSettings(settings)
    })

    ports.displayToast.subscribe(function ({ toastMessage }) {
      // history.push(path)
      notify({
        text: toastMessage,
        type: "success",
      })
    })
  }

  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmModule.AdvisorSettings}
      flags={{
        ...props,
        translationsEnabled: featureFlags.translationsEnabled,
      }}
      ports={setupPorts}
    />
  )
}

export default AdvisorSettings
