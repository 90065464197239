import Label from "~/components/forms/Label"
import React, { useState } from "react"
import styled from "styled-components"
import Input from "./Input"
import Visibility from "~/components/icons/Visibility"
import VisibilityOff from "~/components/icons/VisibilityOff"
import MarkdownTextarea, { FormatOptions } from "~/components/MarkdownTextarea"
import { InputState, Placeholders } from "~/styles/TextStyles"

type InputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "onChange" | "label"
> & {
  label: React.ReactNode
  value: string
  placeholder?: string
  password?: boolean
  hasError?: boolean
  disabled?: boolean
  showInput?: boolean
  markdownOpts?: FormatOptions
  onBlur?: (value: string) => void
  onFocus?: () => void
  onChange: (value: string) => void
  type?: "text" | "email" | "password"
  labelExtra?: React.ReactNode
  htmlExtra?: React.ReactNode
}

const InputCharm = styled.div`
  /* position: absolute; */
  /* right: 1rem; */
  /* top: 0.75rem; */
  cursor: pointer;
  padding: 0.45rem 0.625rem;
`
export const InputWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: ${(p) => p.theme.borderRadius};
  & ${Input} {
    border-radius: 0.5rem;
  }
  ${InputState};
  ${Placeholders};
  ${Input} {
    border: none;
  }
  &:has(${InputCharm}) {
    grid-template-columns: 1fr min-content;
    ${Input} {
      padding-right: 0rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &:focus-within {
    border: 2px solid ${(p) => p.theme.secondary};
  }
  &:has(.quill),
  &:has(.quill):focus-within {
    border: none;
  }
`

const Field: React.FC<InputProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    onChange,
    type = "text",
    showInput = true,
    markdownOpts,
    maxLength,
  } = props
  const [hidden, setHidden] = useState(type === "password")
  const inputType = type === "password" ? (hidden ? "password" : "text") : type
  const [focussed, setFocussed] = useState(false)

  return (
    <div className="form-field">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Label disabled={props.disabled} focussed={focussed}>
          {label}
        </Label>
        {props.labelExtra}
      </div>
      {showInput && (
        <InputWrapper>
          {markdownOpts ? (
            <MarkdownTextarea
              text={value}
              toolbar={markdownOpts}
              disabled={props.disabled}
              hasError={props.hasError}
              maxLength={maxLength}
              onChange={(e) => !props.disabled && onChange(e)}
            />
          ) : (
            <>
              <Input
                type={inputType}
                autoComplete={type === "password" ? "on" : undefined}
                value={value}
                hasError={props.hasError}
                disabled={props.disabled}
                maxLength={maxLength}
                onFocus={() => {
                  setFocussed(true)
                  props.onFocus && props.onFocus()
                }}
                onBlur={() => {
                  setFocussed(false)
                  props.onBlur && props.onBlur(value)
                }}
                onChange={(e) => !props.disabled && onChange(e.target.value)}
                fullWidth
                placeholder={placeholder}
              />
              {type === "password" && (
                <InputCharm
                  className="charm"
                  onClick={() => setHidden(!hidden)}
                >
                  {hidden ? <VisibilityOff /> : <Visibility />}
                </InputCharm>
              )}
            </>
          )}
        </InputWrapper>
      )}
      {props.htmlExtra}
    </div>
  )
}

export default Field
