import React, { createRef, useState } from "react"
import { marked } from "marked"
import DOMPurify from "dompurify"
import styled, { css } from "styled-components"
import Label from "./forms/Label"
import ReactQuill, { Quill } from "react-quill"
import TurndownService from "turndown"
import "react-quill/dist/quill.snow.css"
import { useTranslation } from "react-i18next"

const turndownService = new TurndownService()

const EditorWrapper = styled.div<{
  minHeight?: number
  hasError?: boolean
  italic?: boolean
}>`
  margin-bottom: 0.7rem;
  border-color: ${(p) => p.theme.shade3};

  .quill:focus-within {
    .ql-toolbar,
    .ql-container {
      border-color: ${(p) => p.theme.secondary} !important;
    }
  }
  .ql-toolbar {
    border-radius: 12px 12px 0 0;
    border: 2px solid ${(p) => p.theme.shade3} !important;
    border-bottom: none !important;
  }
  .ql-container {
    border-radius: 0 0 12px 12px;
    border: 2px solid ${(p) => p.theme.shade3} !important;
    border-top: 2px solid ${(p) => p.theme.shade3} !important;
    font-family: "Nunito Sans", "Montserrat", sans-serif;
  }
  .ql-editor {
    min-height: ${(p) => p.minHeight}px;
    padding: 0.5rem 0.75rem;
    overflow: visible;
    font-size: 1rem;
    line-height: 1.5;
    font-style: ${(p) => (p.italic ? "italic" : "normal")};
    border-radius: ${(p) =>
      `0 0 ${p.theme.borderRadius} ${p.theme.borderRadius}`};
    &,
    & * {
      font-family: "Montserrat", "Nunito Sans", sans-serif;
    }
    & ul,
    ol {
      padding-left: 0;
      > li::before {
        font-weight: bold;
      }
    }
    a,
    a:visited {
      text-decoration: underline;
      color: ${(p) => p.theme.secondary};
    }
    *::selection {
      background: rgba(255, 226, 0, 0.75);
    }
  }

  &.disabled .ql-container,
  &.disabled .ql-toolbar {
    background-color: ${(p) => p.theme.shade3};
    color: ${(p) => p.theme.shade4};
    border: 2px solid ${(p) => p.theme.shade2};
    a,
    button {
      pointer-events: none;
      color: ${(p) => p.theme.shade4};
    }
    button svg .ql-stroke {
      stroke: ${(p) => p.theme.shade4};
    }
  }
  .ql-toolbar {
    .ql-formats + .ql-formats {
      border-left: ${(p) => p.theme.shade3} 1px solid;
      padding-left: 15px;
    }
  }
  .ql-snow .ql-tooltip {
    z-index: 3;
    color: ${(p) => p.theme.main};
    border-radius: ${(p) => p.theme.borderRadius};
    border: 2px solid ${(p) => p.theme.shade3};
    box-shadow: ${(p) => p.theme.defaultShadow};

    input {
      border: 2px solid ${(p) => p.theme.shade3};
      padding: ${(p) => p.theme.inputPadding};
      border-radius: ${(p) => p.theme.borderRadius};
    }

    .ql-preview {
      color: ${(p) => p.theme.secondary};
      max-width: 150px;
    }

    .ql-action,
    .ql-remove {
      color: ${(p) => p.theme.secondary};
      font-weight: 600;

      &:after {
        border-right: 2px solid ${(p) => p.theme.shade3};
      }
    }

    &.ql-flip {
      top: initial !important;
      left: initial !important;
    }
  }
  ${(props) =>
    !!props.hasError &&
    css`
      .ql-toolbar,
      .ql-container {
        border-color: ${props.theme.error};
      }
    `}
`
type MarkdownTextareaProps = {
  id?: string
  text: string
  label?: string | React.ReactNode
  isOptional?: boolean
  placeholder?: string
  disabled?: boolean
  toolbar?: FormatOptions
  contentStyle?: string
  maxLength?: number
  minHeight?: number
  bounds?: string
  hasError?: boolean
  onChange: (a: string) => void
  dataTestId?: string
  italic?: boolean
}
type FormatOption = "bold" | "italic" | "link" | "list"
export type FormatOptions = FormatOption[]
const defaultFormatOpts: FormatOptions = ["bold", "italic", "link"]

const MarkdownTextarea: React.FC<MarkdownTextareaProps> = (props) => {
  const id = props.id || "editor"
  return (
    <div key={id}>
      <MarkdownTextareaComp {...props} />
    </div>
  )
}

const quillRef = createRef<ReactQuill>()

const MarkdownTextareaComp: React.FC<MarkdownTextareaProps> = (props) => {
  const formatOptionList = props.toolbar || defaultFormatOpts
  const { t } = useTranslation()

  const [content] = useState(DOMPurify.sanitize(marked.parse(props.text || "")))
  const [focussed, setFocussed] = useState(false)

  const hasFormat = (a) => formatOptionList.includes(a)
  const formatOptions = ["bold", "italic", "link"].filter(hasFormat)
  const listOptions = hasFormat("list")
    ? [{ list: "ordered" }, { list: "bullet" }]
    : []

  document
    .querySelectorAll(".ql-tooltip-editor input")
    ?.forEach((val, key, parent) => {
      val.setAttribute("data-link", "https://aiden.cx")
    })

  var Link = Quill.import("formats/link")
  Link.sanitize = function (url) {
    if (!url.match(/^([a-zA-Z]+:)?\/\//)) return "https://" + url
    return url
  }

  const handleChange = (text: string) => {
    const quill = quillRef.current && quillRef.current.getEditor()
    const maxLength = props.maxLength
    const td = turndownService.turndown(text)

    if (quill && maxLength) {
      quill.on("text-change", function (delta, old, source) {
        if (quill.getLength() > maxLength) {
          quill.deleteText(maxLength, quill.getLength())
        }
      })
    }

    if (td !== props.text) {
      props.onChange(td)
    }
  }

  return (
    <EditorWrapper
      minHeight={props.minHeight ? props.minHeight : 20}
      data-test-id={props.dataTestId}
      className={props.disabled ? "disabled" : ""}
      hasError={props.hasError}
      italic={props.italic}
    >
      {props.label && (
        <Label
          className="form-field__label"
          disabled={props.disabled}
          focussed={!props.disabled && focussed}
        >
          {props.label}
          {!!props.isOptional && (
            <span className="form-field__label-optional">{t("optional")}</span>
          )}
        </Label>
      )}
      <ReactQuill
        ref={quillRef}
        theme="snow"
        defaultValue={content}
        formats={[...formatOptions, hasFormat("list") ? "list" : ""]}
        readOnly={props.disabled}
        onFocus={() => setFocussed(true)}
        onBlur={() => setFocussed(false)}
        modules={{
          toolbar: [formatOptions, listOptions],
        }}
        bounds={props.bounds}
        onChange={handleChange}
      />
    </EditorWrapper>
  )
}

export default MarkdownTextarea
